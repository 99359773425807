// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Random = require("bs-platform/lib/js/random.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var sayings = /* array */[
  "Chill Dev / Grumpy Dev",
  "const isDevGrumpy = Math.random() > 0.5;",
  "grumble grumble grumble",
  "Whiskey Tango Foxtrot",
  "// TODO fix this bullshit later"
];

var component = ReasonReact.reducerComponent("RandomSaying");

function getSaying() {
  return Caml_array.caml_array_get(sayings, Random.$$int(sayings.length));
}

function make() {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("h4", {
                          style: {
                            marginTop: "0px",
                            textAlign: "center",
                            textTransform: "none"
                          },
                          onClick: (function () {
                              return Curry._1(self[/* send */3], /* ChangeSaying */0);
                            })
                        }, self[/* state */1][/* saying */0]);
            }),
          /* initialState */(function () {
              return /* record */[/* saying */getSaying(/* () */0)];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (_, _$1) {
              return /* Update */Block.__(0, [/* record */[/* saying */getSaying(/* () */0)]]);
            }),
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

var $$default = ReasonReact.wrapReasonForJs(component, (function () {
        return make(/* () */0);
      }));

exports.sayings = sayings;
exports.component = component;
exports.getSaying = getSaying;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* component Not a pure module */
