import React, {Fragment} from 'react'
import { Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'

import cdgdLogo from './cdgd.png'

import RandomSaying from './RandomSaying.re';

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <header
          style={{
            ...scale(1.5),
            // marginBottom: rhythm(1.5)
            marginBottom: 0,
            marginTop: 0,
          }}
        >
          <h1>
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={'/'}
            >
              <img src={cdgdLogo} alt="CDGD" style={{marginBottom: '0px'}} />
            </Link>
          </h1>
          <RandomSaying />
        </header>
      )
    } else {
      header = (
        <header
        style={{
          fontFamily: 'Montserrat, sans-serif',
          marginTop: 0,
          // marginBottom: rhythm(-1),
          marginBottom: 0,
        }}>
          <h3>
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={'/'}
            >
              <img src={cdgdLogo} alt="CDGD" style={{height: '4rem'}}/>
            </Link>
          </h3>
          <RandomSaying />
        </header>
      )
    }
    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
      </div>
    )
  }
}

export default Template
